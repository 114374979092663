import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBadge.figmaUrl.ios} codeUrl={checklists.componentBadge.codeUrl.ios} checklists={checklists.componentBadge.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` is a small status descriptor for UI elements. It can be used to display a status or a count of items. It may consists of a small circle, usually containing a number or other short character, that appears near other objects. How to import your theme: Legion iOS UIKit offers four themes: `}<inlineCode parentName="p">{`ThemeAGR`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeIHS`}</inlineCode>{`, `}<inlineCode parentName="p">{`ThemeLGN`}</inlineCode>{`, and `}<inlineCode parentName="p">{`ThemeMyTEnS`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Type`}</h2>
    <p>{`LGNButton have 7 types component, in which each component will be rendered as a solid component:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Primary`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Secondary`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tertiary`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Information`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Warning`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Success`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-primary.png",
              "alt": null,
              "title": "LGNBadge Primary"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-secondary.png",
              "alt": null,
              "title": "LGNBadge Secondary"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-tertiary.png",
              "alt": null,
              "title": "LGNBadge Tertiary"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-information.png",
              "alt": null,
              "title": "LGNBadge Information"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-warning.png",
              "alt": null,
              "title": "LGNBadge Warning"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-error.png",
              "alt": null,
              "title": "LGNBadge Error"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-success.png",
              "alt": null,
              "title": "LGNBadge Success"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Primary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .primary)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Secondary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .secondary)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Tertiary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .tertiary)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Information`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .information)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Warning`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .warning)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Error`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .error)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Success`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(type: .success)
badge.text = "Badge"
`}</code></pre>
    <p>{`You can also change its `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute directly`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge =  LGNBadge()
badge.type = .information
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`You can customize the size of the badge via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` enum, where `}<inlineCode parentName="p">{`BaseLGNBadge.Size`}</inlineCode>{` includes `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{` and `}<inlineCode parentName="p">{`sm`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Badge`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-large.png",
              "alt": null,
              "title": "LGNBadge Large"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-medium.png",
              "alt": null,
              "title": "LGNBadge Medium"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-small.png",
              "alt": null,
              "title": "LGNBadge Small"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Large`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .lg)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Medium`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .md)
badge.text = "Badge"
`}</code></pre>
    <h3>{`Small`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .sm)
badge.text = "Badge"
`}</code></pre>
    <p>{`You can also modify its `}<inlineCode parentName="p">{`size`}</inlineCode>{` attribute directly `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge()
badge.size = .lg
`}</code></pre>
    <div className="divi" />
    <h2>{`Icon`}</h2>
    <p>{`You can insert icon in button on the left side, right side, or even both sides using `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` and `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` attribute.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon Position`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-left-icon.png",
              "alt": null,
              "title": "LGNBadge Left Icon"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-right-icon.png",
              "alt": null,
              "title": "LGNBadge Right Icon"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Both sides`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-left-right-icon.png",
              "alt": null,
              "title": "LGNBadge Left Right Icon"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Left Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge()
badge.text = "Badge"
badge.leftIcon = UIImage(systemName: "heart.fill")
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge()
badge.text = "Badge"
badge.rightIcon = UIImage(systemName: "heart.fill")
`}</code></pre>
    <h3>{`Both Sides Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge()
badge.text = "Badge"
badge.leftIcon = UIImage(systemName: "heart.fill")
badge.rightIcon = UIImage(systemName: "heart.fill")
`}</code></pre>
    <div className="divi" />
    <h2>{`Basic Badge`}</h2>
    <p>{`If you create `}<inlineCode parentName="p">{`LGNBadge`}</inlineCode>{` without any text or icons, it will create a small dot based on it `}<inlineCode parentName="p">{`size`}</inlineCode>{` (default .md) and `}<inlineCode parentName="p">{`type`}</inlineCode>{` (default .primary)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Large`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Medium`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Small`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-large-dot.png",
              "alt": null,
              "title": "LGNBadge Large Dot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-medium-dot.png",
              "alt": null,
              "title": "LGNBadge Medium Dot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/badge/ios-uikit/ios-uikit-badge-small-dot.png",
              "alt": null,
              "title": "LGNBadge Small Dot"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Large Dot`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .lg)
`}</code></pre>
    <h3>{`Medium Dot`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .md)
`}</code></pre>
    <h3>{`Small Dot`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let badge = LGNBadge(size: .sm)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      